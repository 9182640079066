import React, {useEffect, useState} from "react";
import axios from "../../axios";
import {showToast} from "../ToastHelper";
import Loader from "../Loader";

function SendToMFMail({id, mfEmail, countryOfInterest}) {
    const [isMailSent, setIsMailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedEmails, setSelectedEmails] = useState([]);
    const mfEmails = mfEmail ? Object.entries(mfEmail) : [];

    const handleCheckboxChange = (name) => {
        setSelectedEmails((prevSelected) =>
            prevSelected.includes(name)
                ? prevSelected.filter((item) => item !== name)
                : [...prevSelected, name]
        );
    };

    const handleSendToMF = async () => {
        if (selectedEmails.length === 0) {
            showToast("Please select at least one recipient.", "error");
            return;
        }

        try {
            setIsLoading(true);
            const response = await axios.post(`email/sendtomf/${id}`, {
                forward_to_mf: countryOfInterest,
                send_name: selectedEmails, // Send array to backend
            });

            if (response.data.status === "success") {
                showToast(response.data.message, "success");
                setSelectedEmails([]); // Reset selections
            } else {
                showToast(response.data.message, "error");
            }
        } catch (error) {
            showToast(error.response?.data?.message || "An error occurred", "error");
        } finally {
            setIsLoading(false);
        }
    };


    return(
        <>
            {isLoading && <Loader/>}
            <div className="dropdown d-inline-block" style={{marginRight: '5px'}}>
                <button className="btn btn-outline-secondary btn-sm btn-thick dropdown-toggle dropdown-arrow"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        disabled={isMailSent || mfEmails.length === 0}
                >
                    {isMailSent ? 'Sending Mail...' : 'Send To MF'}
                </button>
                <div className="dropdown-menu">
                    {mfEmails.map(([name, email], index) => (
                        <label
                            key={index}
                            className={`dropdown-item ${selectedEmails.includes(name) ? "active" : ""}`}
                            style={{
                                cursor: "pointer",
                                backgroundColor: selectedEmails.includes(name) ? "rgba(0, 0, 0, 0.12)" : "transparent",
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '0'
                            }}
                        >
                            <input
                                type="checkbox"
                                checked={selectedEmails.includes(name)}
                                onChange={() => handleCheckboxChange(name)}
                                style={{marginRight: "5px"}}
                            />
                            {name}: {email}
                        </label>
                    ))}
                    <button
                        className="btn btn-primary btn-sm w-100"
                        onClick={handleSendToMF}
                        disabled={isLoading}
                    >
                        {isLoading ? "Sending..." : "Send to MF"}
                    </button>
                </div>
            </div>
        </>
    )
}

export default SendToMFMail;