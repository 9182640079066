import React, {useState} from "react";
import axios from "../../axios";
import {showToast} from "../ToastHelper";
import Loader from "../Loader";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function ForwardMail({id, countryCode}) {
    const [showForwardModal, setForwardModal] = useState(false);
    const [forwardEmail, setForwardEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [forwardMessage, setForwardMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [responseEmail, setResponseEmail] = useState('');
    const [isMailSent, setIsMailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const predefinedTemplate = `
        <p>Excited to learn more about the Yolé franchise? We’ve got all the details you need!</p>
        <p>Attached, you’ll find everything about our franchise model and what makes Yolé a unique and exciting business opportunity. We’re all about creating a community of passionate partners who share our love for healthy, Delicious Zero Dairy, Zero Sugar Added Ice cream made with oat milk and Zero Sugar Added Signature Froyo!</p>
        <p>Want to get to know us better? Check out our website for more on our brand and what we’re all about.</p>    
    `;

    const toggleForwardModal = () => {
        setForwardModal(!showForwardModal);
        setErrorEmail('');
        setResponseEmail('');
        setForwardEmail('');
        setForwardMessage('');

        // Set the predefined template when opening the modal
        if (!showForwardModal) {
            setForwardMessage(predefinedTemplate);
        } else {
            setForwardMessage('');
        }
    };

    const handleForwardSubmit = async (e) => {
        e.preventDefault();

        // Validate email
        if (!forwardEmail.trim()) {
            setErrorEmail('Email field is required.');
        } else {
            setErrorEmail('');
        }

        // Validate message
        const sanitizedMessage = forwardMessage.replace(/<(.|\n)*?>/g, '').trim(); // Remove all HTML tags
        if (!sanitizedMessage) {
            setErrorMessage('Message field is required.');
        } else {
            setErrorMessage('');
        }

        if (!forwardEmail.trim() || !sanitizedMessage) {
            return;
        }

        try {
            setIsMailSent(true);
            setIsLoading(true);
            const response = await axios.post(`email/forward/${id}`, {
                forward_to: forwardEmail,
                country: countryCode,
                message: forwardMessage
            });

            if (response.data.status === 'success') {
                showToast(response.data.message, 'success');
                toggleForwardModal();
            }
        } catch (error) {
            showToast('Failed to send the email. Please try again.', 'error');
        }finally {
            setIsMailSent(false);
            setIsLoading(false);
        }
    };

    return(
        <>
            {isLoading && <Loader />}
            <button type="button" className="btn btn-sm btn-outline-secondary btn-thick"
                    style={{marginRight: '5px'}} onClick={toggleForwardModal}>
                Forward
            </button>

            {/* Forward Modal */}
            <div className={`modal fade ${showForwardModal ? 'show d-block' : ''}`} tabIndex="-1" role="dialog" style={{ display: showForwardModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Forward Email</h5>
                            <button type="button" className="close" aria-label="Close" onClick={toggleForwardModal}
                                    style={{backgroundColor: 'transparent', border: 'none', fontSize: '22px'}}>
                                <span aria-hidden="true" onClick={toggleForwardModal}>&times;</span>
                            </button>
                        </div>
                        <form onSubmit={handleForwardSubmit}>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="forwardEmail">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="forwardEmail"
                                        placeholder="Write a email here..."
                                        value={forwardEmail}
                                        onChange={(e) => setForwardEmail(e.target.value)}
                                    />
                                    {errorEmail && <div className="text-danger mt-2">{errorEmail}</div>}
                                </div>

                                <div className="form-group" style={{ marginTop: '20px' }}>
                                    <label htmlFor="textarea">Message</label>
                                    <ReactQuill
                                        value={forwardMessage}
                                        onChange={setForwardMessage}
                                        theme="snow"
                                        placeholder="Write a message here..."
                                        style={{height: '300px'}}
                                    />
                                    {errorMessage && <div className="text-danger mt-2">{errorMessage}</div>}
                                </div>
                            </div>
                            <div className="modal-footer" style={{ marginTop: '40px' }}>
                                <button type="button" className="btn btn-secondary" onClick={toggleForwardModal}>
                                    Close
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    {isMailSent ? 'Sending Mail...' : 'Forward'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* Modal backdrop */}
            {showForwardModal && <div className="modal-backdrop fade show"></div>}
        </>
    )
}

export default ForwardMail;