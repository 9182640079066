import React, { useEffect, useState } from "react";
import axios from "../../axios";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../components/Loader";
import {Link, useNavigate} from "react-router-dom";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import {showToast} from "../../components/ToastHelper";
import FranchiseeDetail from "./FranchiseeDetail";

function Franchisee() {
    const [franchiseeList, setFranchiseeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(20);
    const [totalRecords, setTotalRecords] = useState(0);
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedMainInterest, setSelectedMainInterest] = useState('');
    const [selectedInvestment, setSelectedInvestment] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [countryList, setCountryList] = useState([]);
    const navigate = useNavigate();
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [selectedFranchiseeId, setSelectedFranchiseeId] = useState(null);

    useEffect(() => {
        setLoading(true);
        fetchData();
        fetchCountryList();
    }, [first, rows, globalFilter, selectedMainInterest, selectedInvestment, selectedCountry]);

    const fetchData = () => {
        const currentPage = Math.floor(first / rows) + 1;
        let url = `/get_franchisee?page=${currentPage}&pageSize=${rows}&globalFilter=${globalFilter}`;

        if (selectedMainInterest) {
            url += `&main_interest=${selectedMainInterest}`;
        }

        if (selectedInvestment) {
            url += `&capital_to_invest=${selectedInvestment}`;
        }

        if (selectedCountry) {
            url += `&country_of_interest=${selectedCountry}`;
        }
        axios.get(url)
            .then((response) => {
                // console.log('response', response);
                setFranchiseeList(response.data.data);
                setTotalRecords(response.data.totalRecords);
            })
            .catch((error) => {
                toast.error('Something went wrong.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchCountryList = () => {
        axios.get('/country_list')
            .then((response) => {
                const countries = response.data.data.map((country) => ({
                    value: country,
                    label: country,
                }));
                setCountryList(countries);
            })
            .catch((error) => {
                showToast(error, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onPage = (event) => {
        const newFirst = event.first;
        setFirst(newFirst);
        setRows(event.rows);
    };

    const indexTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                {first + franchiseeList.indexOf(rowData) + 1}
            </React.Fragment>
        );
    };

    const nameTemplate = (rowData) => {
        return `${rowData.first_name} ${rowData.last_name}`;
    };

    const handleRowClick = (rowData) => {
        setSelectedFranchiseeId(rowData.id);
        setShowDetailModal(true);
    };

    const handleClose = () => {
        setShowDetailModal(false);
        setSelectedFranchiseeId(null);
        fetchData();
    };


    const filteredData = franchiseeList.filter((item) =>
        Object.values(item).some(
            (val) =>
                val &&
                val.toString().toLowerCase().includes(globalFilter.toLowerCase())
        )
    );

    const moveToCategory = async (franchise_id, category) => {
        setLoading(true);
        try {
            const response = await axios.post(`franchisee/${franchise_id}/add_to_category`, {
                category: category,
            });
            showToast(response.data.message, 'success');
            fetchData();
        }catch (e) {
            showToast('Something went wrong', 'error');
        }finally {
            setLoading(false);
        }
    }

    const handleMainInterestChange = (e) => {
        setSelectedMainInterest(e.target.value); // Update the selected category
        setFirst(0); // Reset pagination when category changes
    };

    const handleInvestmentChange = (e) => {
        setSelectedInvestment(e.target.value); // Update the selected category
        setFirst(0); // Reset pagination when category changes
    };

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption.value);
        // console.log('Selected Country:', selectedOption);
        setFirst(0);
    };

    const handleSearchChange = (e) => {
        setGlobalFilter(e.target.value); // Update the global filter
        setFirst(0); // Reset pagination to the first page
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="">
                                    <h4 className="card-title mb-5">Franchisee List</h4>
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <select className="form-control"
                                                    name="category"
                                                    value={selectedMainInterest}
                                                    onChange={handleMainInterestChange}>
                                                <option value="">Select Interest</option>
                                                <option value="Single Franchise">Single Franchise</option>
                                                <option value="Multiple Franchise">Multiple Franchise</option>
                                                <option value="Master Franchise">Master Franchise</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-3">
                                            <select className="form-control"
                                                    name="capital_to_invest"
                                                    value={selectedInvestment}
                                                    onChange={handleInvestmentChange}>
                                                <option value="">Select Investment</option>
                                                <option value="Less than $100k (USD)">Less than $100k (USD)</option>
                                                <option value="$250k - $500K (USD)">$250k - $500K (USD)</option>
                                                <option value="$500k - $1M (USD)">$500k - $1M (USD)</option>
                                                <option value="More than $1M (USD)">More than $1M (USD)</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-3">
                                            <Select
                                                name="country_of_interest"
                                                value={selectedCountry}
                                                onChange={handleCountryChange}
                                                options={countryList}
                                                isSearchable={true}
                                                isLoading={loading}
                                                placeholder="Select/Write Country"
                                            />
                                        </div>
                                        <div className="col-lg-3">
                                            <InputText
                                                placeholder="Search..."
                                                className="form-control"
                                                value={globalFilter}
                                                onChange={handleSearchChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="table-responsive mt-3">
                                        <DataTable
                                            value={filteredData}
                                            loading={loading}
                                            paginator
                                            paginatorPosition="both"
                                            rows={rows}
                                            totalRecords={totalRecords}
                                            lazy
                                            onPage={onPage}
                                            first={first}
                                            scrollable // Enable scrolling
                                            scrollHeight="600px" // Make the header sticky
                                            onRowClick={(e) => handleRowClick(e.data)} // Row click event
                                            rowClassName={() => 'clickable-row'}
                                        >
                                            <Column header="#" body={indexTemplate} className="text-center"></Column>
                                            <Column header="Name" body={nameTemplate} className="text-capitalize"></Column>
                                            <Column field="company_name" header="Company" className="text-capitalize"></Column>
                                            <Column field="country_of_interest" header="Country" className="text-capitalize"></Column>
                                            <Column field="main_interest" header="Main Interest" className="text-capitalize"></Column>
                                            <Column field="capital_to_invest" header="Investment" className="text-capitalize"></Column>
                                            <Column field="formatted_created_at" header="Date" className="text-capitalize"></Column>
                                            <Column header="Status" body={(rowData) => (
                                                <React.Fragment>
                                                    {rowData.email_status === 'sent_to_mf' ? (
                                                        <span className="badge badge-primary">Send To MF</span>
                                                    ) : rowData.email_status === 'sorry_sent' ? (
                                                        <span className="badge badge-danger">Sorry</span>
                                                    ) : rowData.email_status === 'thank_you_sent' ? (
                                                        <span className="badge badge-success" style={{ backgroundColor: '#4bb542' }}>Thank You</span>
                                                    ) : rowData.email_status === 'forwarded' ? (
                                                        <span className="badge badge-info" style={{ backgroundColor: '#35b5ae' }}>Forwarded</span>
                                                    ) : rowData.email_status === 'custom_message_sent' ? (
                                                        <span className="badge badge-primary">Customized</span>
                                                    ) : rowData.viewed === 0 ? (
                                                        <span className="badge badge-warning">Unread</span>
                                                    ) : (
                                                        <span className="badge badge-warning"></span>
                                                    )}
                                                </React.Fragment>
                                            )}></Column>
                                            <Column header="Gold/Aqua" body={(rowData) => (
                                                <React.Fragment>
                                                    {rowData.category === 'aqua' ? (
                                                        <button type="button" className="btn btn-dark btn-sm"
                                                                onClick={() => moveToCategory(rowData.id, "none")}>Remove from Aqua</button>
                                                    ) : rowData.category === 'gold' ? (
                                                        <button type="button" className="btn btn-warning btn-sm"
                                                                onClick={() => moveToCategory(rowData.id, "none")}>Remove from Gold</button>
                                                    ) : (
                                                        <>
                                                            <button type="button" className="btn btn-dark btn-sm button-style"
                                                                    onClick={() => moveToCategory(rowData.id, "aqua")}>Move To
                                                                Aqua</button>
                                                            <button type="button" className="btn btn-warning btn-sm button-style"
                                                                    onClick={() => moveToCategory(rowData.id, "gold")}>Move To Gold</button>
                                                        </>
                                                    )}
                                                </React.Fragment>
                                            )}></Column>
                                        </DataTable>
                                        {loading && <Loader/>}
                                        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal for Off-Canvas */}
            {showDetailModal && (
                <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog" aria-labelledby="detailModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="detailModalLabel">Franchisee Details</h5>
                                <Link to={`/franchisee/${selectedFranchiseeId}`} style={{marginRight: '6px'}}>View <i className="fa fa-external-link-alt"></i></Link>
                                <button type="button" className="close" onClick={handleClose} aria-label="Close" style={{backgroundColor: 'transparent', border: 'none', fontSize: '22px'}}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {selectedFranchiseeId && <FranchiseeDetail id={selectedFranchiseeId} />}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                                <Link to={`/franchisee/${selectedFranchiseeId}`} className="btn btn-primary" style={{marginRight: '6px'}}>View <i className="fa fa-external-link-alt"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal backdrop */}
            {showDetailModal && <div className="modal-backdrop fade show"></div>}
        </>
    );
}

export default Franchisee;
