import React, {useState} from "react";
import axios from "../../axios";
import {showToast} from "../ToastHelper";
import Loader from "../Loader";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function ThankYouMail({id, countryCode}) {
    const [showThankYouModel, setShowThankYouModel] = useState(false);
    const [customMessage, setCustomMessage] = useState('');
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [isMailSent, setIsMailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const predefinedTemplate = `
        <p>Thank you for reaching out and taking the first step toward exploring the exciting world of Yolé! We’re thrilled to receive your application and can’t wait to share more about what makes Yolé so special.</p>
        <p>To help you get better acquainted with our brand, we’ve attached a presentation packed with everything you need to know. <strong>Inside, you’ll discover:</strong></p>
        <ul>
            <li>✨ Our unique value proposition</li>
            <li>🍦 Our innovative products and offerings</li>
            <li>🏪 The different Yolé units and business models</li>            
        </ul>
        <p>It’s the perfect introduction to what we’re all about and why Yolé is leading the way in healthy indulgence.</p>
        <p>Once you’ve had a chance to review it, let us know if the Yolé brand excites you as much as it excites us. If you’re ready to explore this opportunity further, we’d be delighted to share more details with you!</p>
    `;

    const toggleModal = () => {
        setShowThankYouModel(!showThankYouModel);
        setError('');
        setResponseMessage('');

        // Set the predefined template when opening the modal
        if (!showThankYouModel) {
            setCustomMessage(predefinedTemplate);
        } else {
            setCustomMessage('');
        }
    };

    const handleThankYouSubmit = async (e) => {
        e.preventDefault();

        if (!customMessage.trim()) {
            setError('Custom message field is required.');
            return;
        }

        try {
            setIsMailSent(true);
            setIsLoading(true);
            const response = await axios.post(`email/thankyou/${id}`, {
                message: customMessage,
                country: countryCode
            });

            if (response.data.status === 'success') {
                showToast(response.data.message, 'success');
                toggleModal();
            }
        } catch (error) {
            showToast('Failed to send the email. Please try again.', 'error');
        }finally {
            setIsMailSent(false);
            setIsLoading(false);
        }
    };

    return(
        <>
            {isLoading && <Loader />}
            <button type="button" className="btn btn-sm btn-outline-secondary btn-thick"
                    style={{marginRight: '5px'}} onClick={toggleModal}>
                Thank You
            </button>

            {/* Sorry Modal */}
            <div className={`modal fade ${showThankYouModel ? 'show d-block' : ''}`} tabIndex="-1" role="dialog" style={{ display: showThankYouModel ? 'block' : 'none' }}>
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Thank You Message</h5>
                            <button type="button" className="close" aria-label="Close" onClick={toggleModal} style={{backgroundColor: 'transparent', border: 'none', fontSize: '22px'}}>
                                <span aria-hidden="true"><i className="fa fa-window-close"></i></span>
                            </button>
                        </div>
                        <form onSubmit={handleThankYouSubmit}>
                            <div className="modal-body">
                                <label htmlFor="textarea">Message</label>
                                <ReactQuill
                                    value={customMessage}
                                    onChange={setCustomMessage}
                                    theme="snow"
                                    placeholder="Write a message here..."
                                    style={{ height: '300px' }}
                                />
                                {error && <div className="text-danger mt-2">{error}</div>}
                            </div>
                            <div className="modal-footer mt-5">
                                <button type="button" className="btn btn-secondary" onClick={toggleModal}>
                                    Close
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    {isMailSent ? 'Sending Mail...' : 'Send Message'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* Modal backdrop */}
            {showThankYouModel && <div className="modal-backdrop fade show"></div>}
        </>
    )
}

export default ThankYouMail;